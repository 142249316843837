import {imagesGalleryList} from "../../reducers/dataReducer";
import {useSelector} from "react-redux";

import React, {useEffect, useState} from "react";

import PhotoAlbum from "react-photo-album";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import {Sechead} from "../../components/Sechead/Sechead";

import "./Gallery.sass"
import {MainContacts} from "../../components/MainContacts/MainContacts";
import {Footer} from "../../components/Footer/Footer";
import {Link} from "react-router-dom";

export function Gallery() {
    const [index, setIndex] = useState(-1);
    let images = useSelector(imagesGalleryList);

    const getMeta = (url, cb) => {
        const img = new Image();
        img.src = url;
        img.onload = async () => await cb(null, img);
        img.onerror = (err) => cb(err);
        return img
    };

    let photos = [];
    const [photosList, setList] = useState([]);

    for (let item of images) {
        getMeta(item.url, async (err, img) => {
            photos.push({src: img.src, width: img.naturalWidth, height: img.naturalHeight});
        });
    }

    const [masTrue, setTrue] = useState(false);
    const [masReady, setReady] = useState(false);
    useEffect(() => {
        if (photosList.length !== images.length ){
            setList(photos);
        }else if (photosList.length === images.length && masTrue === false){
            setList(photos);
            setTrue(true);
        }else if (photosList.length === images.length && masTrue === true && masReady === false){
             setList(photos);
             setReady(true);
        }
    }, [images.length, masReady, masTrue, photos, photosList]);




    return (
        <div className={"gallery-main"}>
            <Sechead/>
            <div className={"gallery-main-page"}>
                <div className={"bread"}>
                    <ul>
                        <li><Link to="/">Главная</Link></li>
                        <li>Галерея</li>
                    </ul>
                </div>
                <h1>Галерея</h1>
                {photosList.length === images.length ?
                    <PhotoAlbum photos={photosList} layout="rows" targetRowHeight={300}
                                onClick={({index}) => setIndex(index)} srcSet={photosList}/> :
                    <div>загрузка</div>
                }

                <Lightbox
                    slides={photosList}
                    open={index >= 0}
                    index={index}
                    close={() => setIndex(-1)}
                    // enable optional lightbox plugins
                    plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
                />
            </div>
            <MainContacts/>
            <Footer/>
        </div>
    );
}