import "./WhichSpecialist.sass";
import "animate.css/animate.compat.css"
import ScrollAnimation from 'sgk-react-animation-scroll';
import {Link} from "react-router-dom";

export function WhichSpecialist(){
    return(
        <div className={"which-specialist"} id={"secondheader"}>

            <Link to={"/article/К_кому_обратитьсяq"}><h3>
                Как понять к какому специалисту мне нужно обратиться?
            </h3></Link>
            <svg width="24" height="8" viewBox="0 0 24 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723857 4.5 0.999999 4.5L1 3.5ZM23.3536 4.35358C23.5488 4.15832 23.5488 3.84174 23.3536 3.64647L20.1716 0.46449C19.9763 0.269228 19.6597 0.269227 19.4645 0.464489C19.2692 0.659751 19.2692 0.976334 19.4645 1.1716L22.2929 4.00003L19.4645 6.82845C19.2692 7.02371 19.2692 7.34029 19.4645 7.53556C19.6597 7.73082 19.9763 7.73082 20.1716 7.53556L23.3536 4.35358ZM0.999999 4.5L23 4.50003L23 3.50003L1 3.5L0.999999 4.5Z"
                    fill="black"/>
            </svg>
        </div>
    );
}