import {servicesList} from "../../reducers/dataReducer";
import {useSelector} from "react-redux";
import {useState} from "react";
import "./ServisesMenu.sass"
import {Link} from "react-router-dom";

export function ServisesMenu() {
    let services = useSelector(servicesList);
    const [activeService, setService] = useState(services[0]);

    return (
        <div className={"sub-menu-services"}>
            <div className={"services-column"}>
                <ul>
                    {services.map((service) => {
                        return (
                            <li className={activeService === service ? "active" : ""} key={service.id}
                                onMouseEnter={() => setService(service)}><Link to={"/services/" + service.name.replaceAll(" ", '_')}>{service.name}</Link></li>
                        )
                    })}
                </ul>
            </div>
            <div className={"specs-column"}>
                <ul>
                    <li className={"mobile-link"}><Link to={"/services/" + activeService.name.replaceAll(" ", '_') }>О разделе</Link></li>
                    {activeService.spec_articles.map((spec) => {
                        return (
                            <li key={spec.id} ><Link to={"/spec-articles/" + spec.name.replaceAll(" ", '_') }>{spec.name}</Link></li>
                        )
                    })}
                </ul>
            </div>
            <div className={"diseases-column"}>
                <ul>
                    {activeService.diseases.map((item) => {
                        return (
                            <li key={item.id}><Link to={"/diseases/" + item.name.replaceAll(" ", '_').replaceAll("?", "q") }>{item.name}</Link></li>
                        )
                    })}
                </ul>
            </div>
        </div>
    );
}