import React, {useEffect, useState} from "react";
import {categoriesList, newsList, servicesList, doctorsList} from "../../reducers/dataReducer";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import "./HeaderSearch.sass";
import { IoCloseOutline } from "react-icons/io5";

export function HeaderSearch() {
    const articles_categories = useSelector(categoriesList);
    const services = useSelector(servicesList);
    const news = useSelector(newsList);
    const doctors = useSelector(doctorsList);

    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setResults] = useState({
        articles: [],
        categories: [],
        news: [],
        doctors: [],
        services: [],
        spec_articles:[],
        diseases:[]
    });

    const [checkResults, setCheck] = useState(false);


    useEffect(() => {
        if (searchQuery.length > 3) {
            let search_massive = {
                articles: [],
                categories: [],
                news: [],
                doctors: [],
                services: [],
                spec_articles:[],
                diseases:[]
            }
            for (let category of articles_categories){
                if (category.name.toUpperCase().includes(searchQuery.toUpperCase()) || category.description.toUpperCase().includes(searchQuery.toUpperCase())){
                    search_massive.categories.push(category)
                }
                for (let article of category.articles){
                    if (article.header.toUpperCase().includes(searchQuery.toUpperCase()) || article.text.toUpperCase().includes(searchQuery.toUpperCase()) ||
                        article.author.toUpperCase().includes(searchQuery.toUpperCase())){
                        search_massive.articles.push(article)
                    }
                }
            }
            for (let news_item of news){
                if (news_item.header.toUpperCase().includes(searchQuery.toUpperCase()) || news_item.description.toUpperCase().includes(searchQuery.toUpperCase())){
                    search_massive.news.push(news_item)
                }
            }
            for (let doctor of doctors){
                let name = doctor.name + doctor.patronymics + doctor.surname
                if (doctor.specialty.toUpperCase().includes(searchQuery.toUpperCase()) || doctor.fullspeciality.toUpperCase().includes(searchQuery.toUpperCase()) ||
                    doctor.howiwork.toUpperCase().includes(searchQuery.toUpperCase()) || doctor.metodi.toUpperCase().includes(searchQuery.toUpperCase()) ||
                    name.toUpperCase().includes(searchQuery.toUpperCase())){
                    search_massive.doctors.push(doctor)
                }
            }
            for (let service of services){
                if (service.name.toUpperCase().includes(searchQuery.toUpperCase()) || service.text.toUpperCase().includes(searchQuery.toUpperCase())){
                    search_massive.services.push(service)
                }
                for (let spec_article of service.spec_articles){
                    if (spec_article.name.toUpperCase().includes(searchQuery.toUpperCase()) || spec_article.text.toUpperCase().includes(searchQuery.toUpperCase())){
                        search_massive.spec_articles.push(spec_article)
                    }
                }
                for (let dis of service.diseases){
                    if (dis.name.toUpperCase().includes(searchQuery.toUpperCase()) || dis.text.toUpperCase().includes(searchQuery.toUpperCase())){
                        search_massive.diseases.push(dis)
                    }
                }
            }


            if (search_massive.articles.length>0 || search_massive.categories.length>0 || search_massive.news.length>0 ||
                search_massive.doctors.length>0 || search_massive.services.length>0 || search_massive.spec_articles.length>0 || search_massive.diseases.length>0){
                setCheck(true);
            }
            setResults(search_massive);
        }else {
            setCheck(false);
        }
    }, [searchQuery])
    return (
        <>
            <div className={"second-header-search"}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M9.55185 5.06126C9.55185 7.26549 7.67701 9.12252 5.27592 9.12252C2.87484 9.12252 1 7.26549 1 5.06126C1 2.85703 2.87484 1 5.27592 1C7.67701 1 9.55185 2.85703 9.55185 5.06126ZM8.25133 9.24145C7.4043 9.79736 6.37969 10.1225 5.27592 10.1225C2.36211 10.1225 0 7.85652 0 5.06126C0 2.266 2.36211 0 5.27592 0C8.18974 0 10.5518 2.266 10.5518 5.06126C10.5518 6.44366 9.97412 7.69661 9.03765 8.61004L15.8438 15.1393C16.0431 15.3304 16.0497 15.6469 15.8585 15.8462C15.6673 16.0455 15.3508 16.0521 15.1515 15.8609L8.25133 9.24145Z"
                          fill="#4E4E4E"/>
                </svg>
                <input
                    name="search"
                    type="text"
                    value={searchQuery}
                    onChange={(event) => setSearchQuery(event.target.value)}
                />
                <IoCloseOutline className={"close-icon"} onClick={() => setSearchQuery("")}/>
            </div>
            <div className={checkResults ? "search-result": "hided"}>
                {searchResults.categories.length > 0 &&
                    <div className={"search-result-block"}>
                        <div className={"search-result-label"}>Категории</div>
                        {searchResults.categories.map((category) =>{
                            return(
                                <div className={"search-result-block-item"}><Link to={"/category/" + category.name.replaceAll(" ", '_')} onClick={() => setSearchQuery("")}>{category.name}</Link></div>
                            )
                        })}
                    </div>
                }
                {searchResults.articles.length > 0 &&
                    <div className={"search-result-block"}>
                        <div className={"search-result-label"}>Статьи</div>
                        {searchResults.articles.map((article) => {
                            return(
                                <div className={"search-result-block-item"} key={article.id}>
                                    <Link to={"/article/" + article.header.replaceAll(" ", '_').replaceAll("?", "q")} onClick={() => setSearchQuery("")} >{article.header}</Link>
                                </div>
                            )
                        })}
                    </div>
                }
                {searchResults.news.length > 0 &&
                    <div className={"search-result-block"}>
                        <div className={"search-result-label"}>Новости</div>
                        {searchResults.news.map((news_item) =>{
                            return (
                                <div className={"search-result-block-item"}><Link
                                    to={"/news/" + news_item.header.replaceAll(" ", "_")}  onClick={() => setSearchQuery("")}>{news_item.header}</Link></div>
                            )
                        })}
                    </div>
                }
                {searchResults.doctors.length > 0 &&
                    <div className={"search-result-block"}>
                        <div className={"search-result-label"}>Специалисты</div>
                        {searchResults.doctors.map((doctor) => {
                            return (
                                <div className={"search-result-block-item"}><Link
                                    to={"/doctor/" + doctor.surname + "_" + doctor.name + "_" + doctor.patronymics} onClick={() => setSearchQuery("")}>{doctor.surname+ " " + doctor.name+ " " + doctor.patronymics}</Link></div>
                            )
                        })}
                    </div>
                }
                {searchResults.services.length > 0 &&
                    <div className={"search-result-block"}>
                        <div className={"search-result-label"}>Услуги</div>
                        {searchResults.services.map((service) => {
                            return (
                                <div className={"search-result-block-item"}>
                                    <Link to={"/services/" + service.name.replaceAll(" ", '_')} onClick={() => setSearchQuery("")}>{service.name}</Link>
                                </div>
                            )
                        })}
                    </div>
                }
                {searchResults.spec_articles.length > 0 &&
                    <div className={"search-result-block"}>
                        <div className={"search-result-label"}>Специалисты</div>
                        {searchResults.spec_articles.map((spec_article) => {
                            return (
                                <div className={"search-result-block-item"}>
                                    <Link to={"/spec-articles/" + spec_article.name.replaceAll(" ", '_') } onClick={() => setSearchQuery("")}>{spec_article.name}</Link>
                                </div>
                            )
                        })}
                    </div>
                }
                {searchResults.diseases.length > 0 &&
                    <div className={"search-result-block"}>
                        <div className={"search-result-label"}>Специалисты</div>
                        {searchResults.diseases.map((disease) => {
                            return (
                                <div className={"search-result-block-item"}>
                                    <Link to={"/diseases/" + disease.name.replaceAll(" ", '_') } onClick={() => setSearchQuery("")}>{disease.name}</Link>
                                </div>
                            )
                        })}
                    </div>
                }
            </div>
        </>
    );
}