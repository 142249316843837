import {Sechead} from "../../components/Sechead/Sechead";
import Modal from "react-modal";
import {IoCloseOutline} from "react-icons/io5";
import {Document, Page} from "react-pdf";
import {Link} from "react-router-dom";
import {MainContacts} from "../../components/MainContacts/MainContacts";
import {Footer} from "../../components/Footer/Footer";
import React, {useState} from "react";
import "./Documents.sass";

export function Documents(){
    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);

    const [showModalProtect, setModalProtect] = useState(false);
    const [showModalPersonal, setModalPersonal] = useState(false);
    const [showModalPolicy, setModalPolicy] = useState(false);
    const [showModaGrafic, setModalGrafic] = useState(false);
    const [showModaPersonalLaw, setModalPersonalLaw] = useState(false);

    const availableScreenWidth = window.screen.availWidth;
    const availableScreenHeight = window.screen.availHeight;
    let w;
    let h;
    if (availableScreenWidth < 1440) {
        w = Math.trunc(availableScreenWidth * 0.3);
        h = Math.trunc(availableScreenHeight * 0.9);
    }else if (availableScreenWidth < 1024) {
        w = Math.trunc(availableScreenWidth * 0.9);
        h = Math.trunc(availableScreenHeight * 0.9);
    }else {
        w = Math.trunc(availableScreenWidth * 0.235)
        h = Math.trunc(availableScreenHeight * 0.7);
    }


    function onDocumentLoadSuccess({numPages}) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }
    return (
        <div className={"documents-all"}>
            <Sechead/>
            <div className={"documents-page"}>
                <h1>Документы</h1>
                <ul className={"docs-list"}>
                    <li className={"docs-list-item"}>
                        <div className={"docs-list-item-button"} onClick={() => setModalProtect(true)}>Защита прав
                            потребителей
                        </div>
                        <Modal isOpen={showModalProtect} contentLabel="Форма: Добавить статью" className="Modal"
                               overlayClassName="Overlay">
                            <div className={"docs-list-modal-close-button"} onClick={() => setModalProtect(false)}>
                                <IoCloseOutline size={30}/></div>
                            <Document
                                file={"https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload/prava_potrebitelya.pdf"}
                                onLoadSuccess={onDocumentLoadSuccess}
                                mimeType="application/octet-stream"
                            >
                                <Page pageNumber={pageNumber} renderAnnotationLayer={false} renderTextLayer={false}
                                      width={w}  />
                            </Document>
                            <div className={"pdf-nav"}>
                                <p>
                                    Страница {pageNumber || (numPages ? 1 : '--')} из {numPages || '--'}
                                </p>
                                <button
                                    type="button"
                                    disabled={pageNumber <= 1}
                                    onClick={previousPage}
                                >
                                    Предыдущая
                                </button>
                                <button
                                    type="button"
                                    disabled={pageNumber >= numPages}
                                    onClick={nextPage}
                                >
                                    Следующая
                                </button>
                                <div className={"link-open"}>
                                    <Link to={"https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload/prava_potrebitelya.pdf"} target={"_blank"}>открыть в новой вкладке</Link>
                                </div>
                            </div>
                        </Modal>
                    </li>
                    <li className={"docs-list-item"}>
                        <div className={"docs-list-item-button"} onClick={() => setModalPersonal(true)}>Обработка
                            персональных данных
                        </div>
                        <Modal isOpen={showModalPersonal} contentLabel="Форма: Добавить статью" className="Modal"
                               overlayClassName="Overlay">
                            <div className={"docs-list-modal-close-button"} onClick={() => setModalPersonal(false)}>
                                <IoCloseOutline size={30}/></div>
                            <Document
                                file={"https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload/personal_data_law.pdf"}
                                onLoadSuccess={onDocumentLoadSuccess}
                            >
                                <Page pageNumber={pageNumber} renderAnnotationLayer={false} renderTextLayer={false}
                                      width={w}/>
                            </Document>
                            <div className={"pdf-nav"}>
                                <p>
                                    Страница {pageNumber || (numPages ? 1 : '--')} из {numPages || '--'}
                                </p>
                                <button
                                    type="button"
                                    disabled={pageNumber <= 1}
                                    onClick={previousPage}
                                >
                                    Предыдущая
                                </button>
                                <button
                                    type="button"
                                    disabled={pageNumber >= numPages}
                                    onClick={nextPage}
                                >
                                    Следующая
                                </button>
                                <div className={"link-open"}>
                                    <Link to={"https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload/personal_data_law.pdf"} target={"_blank"}>открыть в новой вкладке</Link>
                                </div>
                            </div>
                        </Modal>
                    </li>
                    <li className={"docs-list-item"}>
                        <div className={"docs-list-item-button"} onClick={() => setModalPolicy(true)}>Политика
                            конфиденциальности
                        </div>
                        <Modal isOpen={showModalPolicy} contentLabel="Форма: Добавить статью" className="Modal"
                               overlayClassName="Overlay">
                            <div className={"docs-list-modal-close-button"} onClick={() => setModalPolicy(false)}>
                                <IoCloseOutline size={30}/></div>
                            <Document
                                file={"https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload/393-415-1-SM.pdf"}
                                onLoadSuccess={onDocumentLoadSuccess}
                            >
                                <Page pageNumber={pageNumber} renderAnnotationLayer={false} renderTextLayer={false}
                                      width={w}/>
                            </Document>
                            <div className={"pdf-nav"}>
                                <p>
                                    Страница {pageNumber || (numPages ? 1 : '--')} из {numPages || '--'}
                                </p>
                                <button
                                    type="button"
                                    disabled={pageNumber <= 1}
                                    onClick={previousPage}
                                >
                                    Предыдущая
                                </button>
                                <button
                                    type="button"
                                    disabled={pageNumber >= numPages}
                                    onClick={nextPage}
                                >
                                    Следующая
                                </button>
                                <div className={"link-open"}>
                                    <Link to={"https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload/393-415-1-SM.pdf"} target={"_blank"}>открыть в новой вкладке</Link>
                                </div>
                            </div>
                        </Modal>
                    </li>
                    <li className={"docs-list-item"}>
                        <div className={"docs-list-item-button"} onClick={() => setModalGrafic(true)}>График работы
                            специалистов
                        </div>
                        <Modal isOpen={showModaGrafic} contentLabel="Форма: Добавить статью" className="Modal"
                               overlayClassName="Overlay">
                            <div className={"docs-list-modal-close-button"} onClick={() => setModalGrafic(false)}>
                                <IoCloseOutline size={30}/></div>
                            <Document
                                file={"https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload/egrul.pdf"}
                                onLoadSuccess={onDocumentLoadSuccess}
                            >
                                <Page pageNumber={pageNumber} renderAnnotationLayer={false} renderTextLayer={false}
                                      width={w}/>
                            </Document>
                            <div className={"pdf-nav"}>
                                <p>
                                    Страница {pageNumber || (numPages ? 1 : '--')} из {numPages || '--'}
                                </p>
                                <button
                                    type="button"
                                    disabled={pageNumber <= 1}
                                    onClick={previousPage}
                                >
                                    Предыдущая
                                </button>
                                <button
                                    type="button"
                                    disabled={pageNumber >= numPages}
                                    onClick={nextPage}
                                >
                                    Следующая
                                </button>
                                <div className={"link-open"}>
                                    <Link to={"https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload/egrul.pdf"} target={"_blank"}>открыть в новой вкладке</Link>
                                </div>
                            </div>
                        </Modal>
                    </li>
                    <li className={"docs-list-item"}>
                        <div className={"docs-list-item-button"} onClick={() => setModalPersonalLaw(true)}>Закон о персональных данных
                        </div>
                        <Modal isOpen={showModaPersonalLaw} contentLabel="Форма: Добавить статью" className="Modal"
                               overlayClassName="Overlay">
                            <div className={"docs-list-modal-close-button"} onClick={() => setModalPersonalLaw(false)}>
                                <IoCloseOutline size={30}/></div>
                            <Document
                                file={"https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload/personal_data_law.pdf"}
                                onLoadSuccess={onDocumentLoadSuccess}
                            >
                                <Page pageNumber={pageNumber} renderAnnotationLayer={false} renderTextLayer={false}
                                      width={w}/>
                            </Document>
                            <div className={"pdf-nav"}>
                                <p>
                                    Страница {pageNumber || (numPages ? 1 : '--')} из {numPages || '--'}
                                </p>
                                <button
                                    type="button"
                                    disabled={pageNumber <= 1}
                                    onClick={previousPage}
                                >
                                    Предыдущая
                                </button>
                                <button
                                    type="button"
                                    disabled={pageNumber >= numPages}
                                    onClick={nextPage}
                                >
                                    Следующая
                                </button>
                                <div className={"link-open"}>
                                    <Link to={"https://api.xn---24-eddkgafeegidb2egfb1b.xn--p1ai/upload/personal_data_law.pdf"} target={"_blank"}>открыть в новой вкладке</Link>
                                </div>
                            </div>
                        </Modal>
                    </li>


                </ul>
            </div>
            <MainContacts/>
            <Footer/>
        </div>
    );
}