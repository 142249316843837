import {Routes, Route, useLocation} from "react-router-dom";
import './App.sass';
import {MainPage} from "./pages/MainPage/MainPage.js";
import {Services} from "./pages/Services/Services.js";
import {Directions} from "./pages/Directions/Directions.js";
import {Contacts} from "./pages/Contacts/Contacts";
import {Specs} from "./pages/Specs/Specs";
import {News} from "./pages/News/News";
import {Admin} from "./pages/Admin/Admin"
import {Video} from "./pages/Video/Video";
import {Blog} from "./pages/Blog/Blog";
import {Article} from "./pages/Article/Article";
import {DoctorPage} from "./pages/Specs/DoctorPage";
import 'react-h5-audio-player/src/styles.scss'
import React from 'react';
import video from "./video/0102.webm";
import {MouseSmooth} from 'react-mouse-smooth';
import {Articles} from "./pages/Articles/Articles";
import {Categories} from "./pages/Article/Categories";
import {SpecArticles} from "./pages/Services/SpecArticles";
import {Diseases} from "./pages/Services/Diseases";
import {Gallery} from "./pages/Gallery/Gallery";
import {VideoPage} from "./pages/Video/VideoPage";

import Favicon from "react-favicon";
import fav from "./images/favicon.ico"
import {NewsItem} from "./pages/News/NewsItem";
import {Regulatory} from "./pages/Regulatory/Regulatory";
import {Licenses} from "./pages/Licenses/Licenses";
import {Documents} from "./pages/Documents/Documents";
import {Feedback} from "./pages/Feedback/Feedback";
import {Vacancies} from "./pages/Vacancies/Vacancies";

function ScrollToTop() {
        const {pathname} = useLocation();

        React.useEffect(() => {
            window.scrollTo(0, 0);
        }, [pathname]);

        return null
    }

function App() {
    MouseSmooth({time: 1000, size: 200, keyboardSupport: true});


    return (
        <div className="App">
            <Favicon url={fav} />
            <header className="App-header">

            </header>
            <div className="container-fluid">

                <div className={"video-back"}>
                    <video className="video" loop={true} autoPlay="autoplay" muted="muted" preload="auto">
                        <source src={video} type="video/mp4"/>
                    </video>
                </div>
                <div className="main-content">
                    <ScrollToTop />
                    <Routes>
                        <Route path="/" element={<MainPage/>}/>
                        <Route path="/contacts" element={<Contacts/>}/>
                        <Route path="/directions" element={<Directions/>}/>
                        <Route path="/video" element={<VideoPage/>}/>
                        <Route path="/videos/:category_name" element={<Video/>}/>
                        <Route path="/specs" element={<Specs/>}/>
                        <Route path="/news" element={<News/>}/>
                        <Route path="/news/:news_head" element={<NewsItem/>}/>
                        <Route path="/blog" element={<Blog/>}/>
                        <Route path="/article/:article_name" element={<Article/>}/>
                        <Route path="/category/:category_name" element={<Categories/>}/>
                        <Route path="/articles" element={<Articles/>}/>
                        <Route path="/doctor/:fio" element={<DoctorPage/>}/>
                        <Route path="/adminka" element={<Admin/>}/>
                        <Route path="/services/:service_name" element={<Services/>}/>
                        <Route path="/spec-articles/:spec_name" element={<SpecArticles/>}/>
                        <Route path="/diseases/:dis_name" element={<Diseases/>}/>
                        <Route path="/gallery" element={<Gallery/>}/>
                        <Route path="/regulatory" element={<Regulatory/>}/>
                        <Route path="/licenses" element={<Licenses/>}/>
                        <Route path="/documents" element={<Documents/>}/>
                        <Route path="/feedback" element={<Feedback/>}/>
                        <Route path="/vacancies" element={<Vacancies/>}/>
                    </Routes>
                </div>
            </div>
        </div>
    );
}

export default App;
