import React from "react";
import intro_full from "../../video/intro2.mp4";
import intro_mobile from "../../video/intro_mobile.mp4";
import "./Intro.sass";
import {AnimateKeyframes} from "react-simple-animate";
import {visIntro, changeVisIntro} from "../../reducers/dataReducer";
import {useDispatch, useSelector} from "react-redux";

export function Intro() {
    let vis = useSelector(visIntro);
    let intro;
    const availableScreenWidth = window.screen.availWidth;
    if (availableScreenWidth < 1024 ){
        intro = intro_mobile;
    }else {
        intro = intro_full;
    }
    const dispatch = useDispatch();

    setTimeout(() => dispatch(changeVisIntro()), 6500);
    return (
        <div className={vis ? "intro" : "hided"}>
                <AnimateKeyframes
                        play
                        delay={5.7}
                        duration={1}
                        keyframes={["opacity: 1", "opacity: 0"]}
                        iterationCount={1}
                    >
                <div className={"focus-back"}></div>
                {vis &&

                        <video className="video" autoPlay="autoplay" muted="muted" preload="auto">
                            <source src={intro} type="video/mp4"/>
                        </video>

                }
                </AnimateKeyframes>
        </div>
    );
}