import "./OnlineRecord.sass"
import React, {useState} from "react";
import validator from "validator";
import {withMask} from "use-mask-input";
import * as Icon from "react-icons/fi";
import Checkbox from "react-custom-checkbox";
import Modal from "react-modal";
import { IoCloseOutline } from "react-icons/io5";

export function OnlineRecord(){
    const [showModal, setModal] = useState(false);

    const [clientName, setName] = useState("");
    const [clientPhone, setPhone] = useState("");
    const [checkBox, setCheck] = useState(false);

    const [inputsValid, setValid] = useState({
        name: validator.isLength(clientName, {min: 2, max: 40}),
        phone: validator.isMobilePhone(clientPhone, 'ru-RU'),
        agree: false,
    });
    const formValid = (values) => {
        return !(values.name === true &&
            values.phone === true &&
            values.agree === true);
    };



    return (
        <div className={"online-record"}>
            <div className={"button-priem"}>
                <button onClick={() => setModal(true)}>Записаться на приём</button>
            </div>
            <Modal isOpen={showModal} contentLabel="Форма: Добавить статью" className={"modal-record"}>
                <div className={"your-number"}>Оставьте Ваш номер и наш менеджер свяжется с вами в ближайшее время</div>
                <form>
                    <label className={"input-label"}>
                        <input
                            name="client-name"
                            type="text"
                            placeholder={"Ваше имя:"}
                            value={clientName}
                            onChange={(event) => setName(event.target.value)}
                            onInput={(event) =>
                                setValid({
                                    ...inputsValid,
                                    name: validator.isLength(event.target.value, {
                                        min: 2,
                                        max: 40,
                                    }),
                                })
                            }
                        />
                        <span className="warning">
                              {inputsValid.name === true
                                  ? ""
                                  : "Введите имя"}
                            </span>
                    </label>
                    <label className={"input-label"}>
                        <input
                            name="client-phone"
                            type="text"
                            placeholder={"Ваш телефон:"}
                            value={clientPhone}
                            onChange={(event) => setPhone(event.target.value)}
                            onInput={(event) => {
                                let phone = event.target.value;
                                phone = phone.replace(/[^0-9]/g, '');
                                setValid({
                                    ...inputsValid,
                                    phone: validator.isMobilePhone(phone, 'ru-RU'),
                                })
                            }

                            }
                            ref={withMask('+7(999)999-99-99')}
                        />
                        <span className="warning">
                              {inputsValid.phone === true
                                  ? ""
                                  : "Введите телефон"}
                            </span>
                    </label>
                    <Checkbox
                        checked={false}
                        icon={
                            <div
                                style={{
                                    display: "flex",
                                    flex: 1,
                                    backgroundColor: "#60a829",
                                    alignSelf: "stretch",
                                }}
                            >
                                <Icon.FiCheck color="white" size={20}/>
                            </div>
                        }
                        borderColor="#C1C1C1"
                        onChange={() => {
                            setCheck((current) => !current);
                            if (checkBox === false) {
                                setValid({
                                    ...inputsValid,
                                    agree: true,
                                });
                            } else {
                                setValid({
                                    ...inputsValid,
                                    agree: false,
                                });
                            }
                        }
                        }
                        borderWidth={1}
                        borderRadius={20}
                        style={{overflow: "hidden"}}
                        size={20}
                        className={"agree-checkbox"}
                        label={<div style={{
                            textAlign: "center",
                        }} className={"agree-label"}
                        >
                            Нажимая «Отправить», вы даете <a href={"#"}>согласие</a> на обработку персональных данных
                        </div>}
                    />
                    <button
                        className="send-problem"
                        disabled={formValid(inputsValid)}
                    >
                        Отправить
                    </button>
                </form>
                <button onClick={() => setModal(false)} className="closeButton">
                    <IoCloseOutline />
                </button>
            </Modal>
        </div>
    );
}