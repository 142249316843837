import {useSelector} from "react-redux";
import {categoriesList} from "../../reducers/dataReducer";
import {Helmet} from "react-helmet";
import {Sechead} from "../../components/Sechead/Sechead";
import React from "react";
import parse from "html-react-parser";
import "./Vacancies.sass"
import {Link} from "react-router-dom";
import {MainContacts} from "../../components/MainContacts/MainContacts";
import {Footer} from "../../components/Footer/Footer";

export function Vacancies(){
    let categories = useSelector(categoriesList);

    let vacancies;

    for (let category of categories){
        if (category.id === 6){
            for (let article of category.articles) {
                if (article.article_id === 144){
                    vacancies = article;
                }
            }
        }
    }

    return(
        <div className={"vacancies-main"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Возрождение жизни - Вакансии</title>
            </Helmet>
            <Sechead/>
            <div className={"vacancies-page"}>
                <div className={"bread"}>
                    <ul>
                        <li><Link to="/">Главная</Link></li>
                        <li>{vacancies.header}</li>
                    </ul>
                </div>
                <h1>{vacancies.header}</h1>
                <div className={"vacancies-text"}>
                    {parse(vacancies.text)}
                </div>
            </div>
            <MainContacts/>
            <Footer/>
        </div>
    );
}